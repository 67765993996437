import { parse } from 'node-html-parser';
import { styled } from '@mui/material/styles';
import type { Testimonial as TestimonialType } from 'types/ekomi';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { StarRating } from './StarRating';

const Quote = styled(Typography)`
  overflow: hidden;
  font-size: 14px;
`;

const WhiteBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  scrollSnapAlign: 'start',
  minWidth: `calc(100% / 3 - ${theme.spacing(3)})`,

  [theme.breakpoints.down('md')]: {
    minWidth: `calc(50% - ${theme.spacing(2)})`,
  },

  [theme.breakpoints.down('sm')]: {
    minWidth: `calc(80% - ${theme.spacing(1)})`,
  },
}));

const DetailWrapper = styled('div')`
  line-height: 8px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Testimonial = ({ data }: { data: TestimonialType }) => {
  const dateString = new Date(Number(data.submitted) * 1000).toLocaleString(
    'de-DE',
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Berlin',
    },
  );

  //truncate the reviews to a max length and adding ...
  const { review } = data;

  const maxCharacters = 250;

  const shortenedReview =
    review.length > maxCharacters
      ? review.slice(0, maxCharacters) + ' …'
      : review;

  // review needs to be parsed to display special characters (like emojis 👍) correctly (CAT-1771)
  const parsedShortenedReview = parse(shortenedReview).textContent;

  return (
    <WhiteBox p={3} m={1.5} aria-roledescription="slide" role="slide">
      <Box mb={4}>
        <StarRating stars={Number(data.rating)} />
        <Quote variant="body2" mt={1}>
          &quot;{parsedShortenedReview}&quot;
        </Quote>
      </Box>
      <DetailWrapper>
        <Typography variant="caption">
          {dateString.replace(', ', ' um ')} Uhr auf{' '}
          <StyledLink
            href="https://www.ekomi.de/bewertungen-finanzcheck.html"
            target="_blank"
            rel="noreferrer"
          >
            ekomi.de
          </StyledLink>
        </Typography>
      </DetailWrapper>
    </WhiteBox>
  );
};
